import {
  Button,
  // Collapse,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItemText,
  Menu,
  Snackbar,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  AccountBox,
  // ExpandLess,
  // ExpandMore,
  History as HistoryIcon,
  Home,
  Menu as MenuIcon,
  Storage,
  Redeem,
  MailOutline as MailIcon,
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MuiAlert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import React, {
  // useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import { Archive, Package } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PopupNotification from '../../components/PopupNotification';
import { useStores } from '../../contexts/storesContext';
import SidebarItem from '../../pages/Items/SidebarItem';
import SidebarLand from '../../pages/Lands/SidebarLand';
import { ExternalText } from '../Common';
import Header from '../Header';
import { AppBar, ListItem, ListItemIcon } from './styled';
import GA from '../../utils/ga';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: 280,
      top: 0,
      backgroundColor: '#28293d',
      height: '100vh',
      padding: '24px 16px 16px',
      [theme.breakpoints.up('md')]: {
        top: '60px',
        height: 'calc(100vh - 60px)',
        padding: '0 16px',
      },
    },
  })
);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapped = styled.div`
  margin-top: 60px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0;
  `};
  @media screen and (min-width: 960) {
    display: flex;
  }
`;

const WrappedContent = styled.main`
  flex-grow: 1;
`;

const StyledDrawer = styled(Drawer)``;

const StyledHome = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 50px;
  background: ${({ theme }) => theme.bg0};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    height: 64px;
  `};
`;

const activeClassName = 'active';

const StyledNavLink = styled(NavLink).attrs(() => ({
  activeClassName,
}))`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  img {
    margin-right: 10px;
    height: 29px;
  }
  white-space: nowrap;
  &.${activeClassName} {
    color: ${({ theme }) => theme.primary};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `};
`;

const StyledMenuFilter = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 100%;
  background: ${({ theme }) => theme.cardBG};
  position: absolute;
  top: 63px;
  min-height: 100vh;
  z-index: 2;
  left: 0;
  animation: fade-in 0.3s forwards;
  .fade-in {
    animation: fade-in 0.3s forwards;
  }
  .fade-out {
    animation: fade-out 0.3s forwards;
  }
`;

const StyledWrapNav = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const StyledWrapNavPc = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`;

const MenuFlyout = styled(Menu)`
  .MuiPaper-root {
    min-width: 10.125rem;
    background-color: ${({ theme }) => theme.cardBG2};
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    left: 12px !important;
    margin-top: 8px;
  }
`;

const MenuItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
  }
`;

const StyledWrapDrawer = styled.div``;

const StyledList = styled(List)`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const MENU_ITEMS_MOBILE = [
  {
    key: 'Home',
    to: '/marketplace',
    icon: (
      <div style={{ marginRight: '5px' }}>
        <Home />
      </div>
    ),
  },
  {
    key: 'Account',
    to: '/profile/account',
    icon: <AccountBox style={{ fontSize: 24 }} />,
  },
  {
    key: 'In-Game’s Inventory',
    to: '/profile/game-inventory',
    icon: <Archive />,
    exact: true,
  },
  {
    key: 'Wallet’s Inventory',
    to: '/profile/wallet-inventory',
    icon: <Package />,
    exact: true,
  },
  {
    key: 'Activity',
    to: '/profile/activities',
    icon: <HistoryIcon style={{ fontSize: 24 }} />,
    exact: true,
  },
  {
    key: 'In-Game’s History',
    to: '/profile/game-history',
    icon: <Storage style={{ fontSize: 24 }} />,
    exact: true,
  },
  {
    key: 'Redeem code',
    to: '/profile/gift',
    icon: <Redeem style={{ fontSize: 24 }} />,
    exact: true,
  },
  {
    key: 'Mail Box',
    to: '/profile/mailbox',
    icon: <MailIcon style={{ fontSize: 24 }} />,
    exact: true,
  },
  {
    key: 'Support',
    href: 'https://support.wanakafarm.com/',
    icon: <HelpOutlineIcon style={{ fontSize: 24 }} />,
    exact: true,
  },
];

export const MENU_ITEMS = [
  {
    key: 'Home',
    to: '/',
    icon: (
      <div style={{ marginRight: '5px' }}>
        <Home />
      </div>
    ),
  },
];

const useUnload = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};

const AppLayout = observer(({ children }: { children: React.ReactNode }) => {
  const {
    root: { notificationStore, notificationModalStore },
  } = useStores();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const referralCode = queryParameters.get('ref') || '';

  useUnload(() => {
    GA.actionPageEvent({ event: 'page_exit' });
  });
  // useEffect(() => {
  //   try {
  //     const authData = getCookie('auth_data')
  //       ? JSON.parse(getCookie('auth_data'))
  //       : '';
  //     const { accessToken } = authData;
  //     if (accessToken) {
  //       userStore.reLoginByAccessToken(accessToken);
  //     }
  //   } catch (e) {
  //     // do nothing
  //   }
  // }, [userStore]);

  useEffect(() => {
    localStorage.setItem('referralCode', referralCode);
  }, []);

  const {
    open,
    onClose,
    message,
    autoHideDuration,
    severity,
    anchorOrigin,
  } = notificationStore;

  const {
    message: messagePopup,
    desc,
    onClose: onClosePopup,
  } = notificationModalStore;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  // const [activatedMenu, setActivatedMenu] = useState<{
  //   [id: string]: boolean;
  // }>({});

  const showFilter =
    location.pathname === '/lands' || location.pathname === '/items';
  const isPageItems = location.pathname === '/items';
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const toggleSubMenu = useCallback((e: React.MouseEvent, id: string) => {
  //   e.stopPropagation();
  //   setActivatedMenu(prevState => {
  //     return Object.assign({}, prevState, { [id]: !prevState[id] });
  //   });
  // }, []);

  const handleClick = event => {
    setOpen(oldState => !oldState);
  };
  const onClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <StyledList>
      <Hidden mdUp={true} implementation="css">
        {MENU_ITEMS_MOBILE.map((menu: any) => {
          // const hasSub = Array.isArray(menu.items) && menu.items.length;
          // const activated = activatedMenu[menu.key];
          const attrs: any = {};
          if (menu.href) {
            attrs.component = 'a';
            attrs.href = menu.href;
            attrs.target = '_blank';
          } else {
            attrs.component = NavLink;
            attrs.to = menu.to;
            attrs.exact = menu.exact;
          }
          return (
            <React.Fragment key={menu.key}>
              <ListItem
                button={true}
                {...attrs}
                style={{ marginBottom: '1rem' }}
              >
                <ListItemIcon>{menu.icon || null}</ListItemIcon>
                <ListItemText
                  primary={
                    menu.href ? (
                      <ExternalText>{menu.key}</ExternalText>
                    ) : (
                      menu.key
                    )
                  }
                />
                {/* {hasSub ? (
                  <IconButton onClick={e => toggleSubMenu(e, menu.key)}>
                    {activated ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                ) : null} */}
              </ListItem>
              {/* {activated ? (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                  <List component="div" disablePadding={true}>
                    {menu.items.map((item, i) => (
                      <ListItem
                        key={`${item.key}_${i}`}
                        button={true}
                        className={'sub-menu'}
                      >
                        <ListItemIcon>{item.icon || null}</ListItemIcon>
                        <ListItemText primary={item.key} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              ) : null} */}
            </React.Fragment>
          );
        })}
      </Hidden>
      <Hidden smDown={true} implementation="css">
        {MENU_ITEMS.map((menu: any) => {
          // const hasSub = Array.isArray(menu.items) && menu.items.length;
          // const activated = activatedMenu[menu.key];
          const attrs: any = {};
          if (menu.href) {
            attrs.component = 'a';
            attrs.href = menu.href;
            attrs.target = '_blank';
          } else {
            attrs.component = NavLink;
            attrs.to = menu.to;
            attrs.exact = menu.exact;
          }
          return (
            <React.Fragment key={menu.key}>
              <ListItem
                button={true}
                {...attrs}
                style={{ marginBottom: '1rem' }}
              >
                <ListItemIcon>{menu.icon || null}</ListItemIcon>
                <ListItemText
                  primary={
                    menu.href ? (
                      <ExternalText>{menu.key}</ExternalText>
                    ) : (
                      menu.key
                    )
                  }
                />
                {/* {hasSub ? (
                  <IconButton onClick={e => toggleSubMenu(e, menu.key)}>
                    {activated ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                ) : null} */}
              </ListItem>
              {/* {activated ? (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                  <List component="div" disablePadding={true}>
                    {menu.items.map((item, i) => (
                      <ListItem
                        key={`${item.key}_${i}`}
                        button={true}
                        className={'sub-menu'}
                      >
                        <ListItemIcon>{item.icon || null}</ListItemIcon>
                        <ListItemText primary={item.key} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              ) : null} */}
            </React.Fragment>
          );
        })}
      </Hidden>
    </StyledList>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <AppBar
        open={open}
        position={'relative'}
        color={'transparent'}
        elevation={0}
      >
        <Header
          toggles={
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          }
        />
      </AppBar>

      <Wrapped>
        <StyledWrapDrawer>
          <Hidden smUp={true} implementation="css">
            <StyledDrawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </StyledDrawer>
          </Hidden>
          {/* <Hidden xsDown={true} implementation="css">
            <StyledDrawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={true}
            >
              {drawer}
            </StyledDrawer>
          </Hidden> */}
        </StyledWrapDrawer>
        <WrappedContent>
          <StyledHome>
            <StyledWrapNavPc>
              <Button
                aria-controls="fade-menu"
                aria-haspopup="true"
                variant="contained"
                size="small"
                onClick={onClickMenu}
                className="btn-flat"
              >
                Wanaka Farm <ExpandMoreIcon />
              </Button>
              <MenuFlyout
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                elevation={0}
                getContentAnchorEl={null}
                keepMounted={true}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <StyledNavLink to="/lands">
                    <img
                      src={require('../../assets/images/tab-land.png')}
                      alt=""
                    />
                    Lands
                  </StyledNavLink>
                </MenuItem>
                <MenuItem>
                  <StyledNavLink to="/items">
                    <img
                      src={require('../../assets/images/tab-item.png')}
                      alt=""
                    />
                    Items
                  </StyledNavLink>
                </MenuItem>
                {/* <MenuItem>
                  <StyledNavLink to="/rental">
                    <img
                      src={require('../../assets/images/tab-rental.svg')}
                      alt=""
                    />
                    Rental system
                  </StyledNavLink>
                </MenuItem> */}
                {/* <MenuItem>
                  <StyledNavLink to="/lottery">
                    <img
                      src={require('../../assets/images/tab-lottery.svg')}
                      alt=""
                    />
                    Lottery
                  </StyledNavLink>
                </MenuItem> */}
                {/* <MenuItem>
                  <StyledNavLink to="/token-claim">
                    <img
                      src={require('../../assets/images/market/icon-wai.png')}
                      alt=""
                    />
                    Claim Token
                  </StyledNavLink>
                </MenuItem> */}
                {/* <MenuItem>
                  <StyledNavLink to="/nft-whitelist">
                    <img
                      src={require('../../assets/images/tab-land.png')}
                      alt=""
                    />
                    NFT Whitelist
                  </StyledNavLink>
                </MenuItem>
                <MenuItem>
                  <StyledNavLink to="/nft-sale">
                    <img
                      src={require('../../assets/images/tab-land.png')}
                      alt=""
                    />
                    NFT Sale
                  </StyledNavLink>
                </MenuItem> */}
              </MenuFlyout>
            </StyledWrapNavPc>
            <StyledWrapNav>
              <StyledNavLink to="/lands">
                <img src={require('../../assets/images/tab-land.png')} alt="" />
                Lands
              </StyledNavLink>
              <StyledNavLink to="/items">
                <img src={require('../../assets/images/tab-item.png')} alt="" />
                Items
              </StyledNavLink>
              {/* <StyledNavLink to="/rental">
                <img
                  src={require('../../assets/images/tab-rental.svg')}
                  alt=""
                />
                Rental system
              </StyledNavLink> */}
              {/* <StyledNavLink to="/land-sale">
                <img src={require('../../assets/images/tab-land.png')} alt="" />
                Land sale
              </StyledNavLink> */}
              {/* <StyledNavLink to="/lottery">
                <img
                  src={require('../../assets/images/tab-lottery.svg')}
                  alt=""
                />
                Lottery
              </StyledNavLink> */}
              {/* <StyledNavLink to="/token-claim">
                <img
                  src={require('../../assets/images/market/icon-wai.png')}
                  alt=""
                />
                Claim Token
              </StyledNavLink>
              <StyledNavLink to="/nft-whitelist">
                <img
                  src={require('../../assets/images/tab-whitelist.svg')}
                  alt=""
                />
                NFT Whitelist
              </StyledNavLink>
              <StyledNavLink to="/nft-sale">
                <img src={require('../../assets/images/tab-sale.svg')} alt="" />
                NFT Sale
              </StyledNavLink> */}
            </StyledWrapNav>

            {isMobile && showFilter && (
              <div>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  size="small"
                >
                  Filters
                </Button>
                <StyledMenuFilter open={isOpen}>
                  {isPageItems ? <SidebarItem /> : <SidebarLand />}
                </StyledMenuFilter>
              </div>
            )}
          </StyledHome>
          {children}
        </WrappedContent>
      </Wrapped>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <PopupNotification
        message={messagePopup}
        desc={desc}
        onClose={onClosePopup}
      />
    </>
  );
});

export default AppLayout;
